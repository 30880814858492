import './App.css'
import Hero from './pages/Hero'
import Offer from './pages/Offer'
import About from './pages/About'
import ContactForm from './pages/ContactForm'
import Footer from './components/Footer'
import { useState } from 'react'

function App () {
  const [selectedPackage, setSelectedPackage] = useState('basic') // Der Zustand für das ausgewählte Angebot

  // Diese Funktion wird in der Offer-Komponente aufgerufen, um das Angebot auszuwählen
  const handlePackageSelect = packageName => {
    setSelectedPackage(packageName)
    document.getElementById('kontakt').scrollIntoView({ behavior: 'smooth' }) // Scrollen zur Kontaktform
  }

  return (
    <div className='App'>
      <section id='hero'>
        <Hero />
      </section>
      <section id='angebot'>
        <Offer onPackageSelect={handlePackageSelect} />{' '}
        {/* Übergebe die Auswahlfunktion */}
      </section>
      <section id='ueber-uns'>
        <About />
      </section>
      <section id='kontakt'>
        <ContactForm selectedPackage={selectedPackage} />{' '}
        {/* Übergebe das ausgewählte Angebot */}
      </section>
      <Footer />
    </div>
  )
}

export default App
