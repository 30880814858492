// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore' // Import for Firestore

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyB2Oj8VpZH0z6-T6XM4eYzvOzqxxf6jzFo',
  authDomain: 'kex-websolutions.firebaseapp.com',
  projectId: 'kex-websolutions',
  storageBucket: 'kex-websolutions.appspot.com',
  messagingSenderId: '171053332498',
  appId: '1:171053332498:web:5ceadecb9ab44eb02880cb'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

// Initialize Firestore
const db = getFirestore(app)

export { db }
