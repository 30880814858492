import React, { useEffect, useState } from 'react'
import Logo from '../resources/pandaLogo.webp'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import './style/Hero.css'

const Hero = () => {
  const [width, setWidth] = useState(window.innerWidth)
  const [headerExpanded, setHeaderExpanded] = useState(false)

  const handleMenuClick = () => {
    setHeaderExpanded(!headerExpanded)
  }

  const scrollToSection = sectionId => {
    const section = document.getElementById(sectionId)
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' })
      if (headerExpanded) {
        setHeaderExpanded(!headerExpanded)
      }
    }
  }

  const scrollDown = () => {
    window.scrollBy({
      top: window.innerHeight,
      behavior: 'smooth'
    })
  }

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth)

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <div className='hero'>
      <div className='heroLeftHalf'>
        <div className='heroLogoDiv' onClick={() => scrollToSection('hero')}>
          <img src={Logo} alt='Panda Logo' className='heroLogo' />
          <h1 className='heroHeader'>KEX</h1>
        </div>
        {width < 850 && (
          <>
            {width < 600 ? (
              <>
                <div
                  className='menuDiv heroHeaderRight'
                  onClick={() => handleMenuClick()}
                >
                  <MenuIcon />
                </div>
                {headerExpanded && (
                  <div className='headerExpanded'>
                    <div
                      className='headerExpandedItem'
                      onClick={() => scrollToSection('angebot')}
                    >
                      <p>Angebot</p>
                    </div>
                    <div
                      className='headerExpandedItem'
                      onClick={() => scrollToSection('ueber-uns')}
                    >
                      <p>Über uns</p>
                    </div>
                    <div
                      style={{ marginBottom: '1rem' }}
                      className='headerExpandedItem'
                      onClick={() => scrollToSection('kontakt')}
                    >
                      <p>Kontakt</p>
                    </div>
                    <div className='closeIcon'>
                      <CloseIcon onClick={() => handleMenuClick()} />
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className='heroHeaderRight'>
                <p onClick={() => scrollToSection('angebot')}>Angebot</p>
                <p onClick={() => scrollToSection('ueber-uns')}>Über uns</p>
                <button
                  className='ctaButton'
                  style={{ backgroundColor: '#333', color: 'white' }}
                  onClick={() => scrollToSection('kontakt')}
                >
                  Kontakt
                </button>
              </div>
            )}
          </>
        )}
        <div className='heroContent heroRelativPos'>
          <h1>Professionelle Websites für jedes Budget!</h1>
          <h4>Maßgeschneiderte Websites für Ihr Unternehmen.</h4>
        </div>
      </div>
      <div className='heroRightHalf'>
        {width > 850 && (
          <div className='heroHeaderRight'>
            <p onClick={() => scrollToSection('angebot')}>Angebot</p>
            <p onClick={() => scrollToSection('ueber-uns')}>Über uns</p>
            <button
              className='ctaButton'
              onClick={() => scrollToSection('kontakt')}
            >
              Kontakt
            </button>
          </div>
        )}

        <div className='heroContentRight heroRelativPos'>
          <div className='bigOffer'>
            <p>ab</p>
            <h1>299€</h1>
          </div>
          <div className='subHeading'>
            Noch nie sind <span>professionelle</span> Websites so preiswert
            gewesen!
          </div>
          <div className='arrowDown' onClick={scrollDown}>
            <ArrowDownwardIcon
              style={{ fontSize: '2rem', cursor: 'pointer' }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
