import React from 'react'
import InstagramIcon from '@mui/icons-material/Instagram'
import FacebookIcon from '@mui/icons-material/Facebook'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import './Footer.css'

const Footer = () => {
  return (
    <div className='footer'>
      <footer>
        <p>© 2021 KEX Web Solutions</p>
        <div className='iconDiv'>
          <a
            href='https://www.instagram.com/kex_websolutions.at/'
            className='icon'
          >
            <InstagramIcon />
          </a>
          <a
            className='icon'
            href='https://www.facebook.com/profile.php?id=61565138081756'
          >
            <FacebookIcon />
          </a>
          <a
            className='icon'
            href='https://www.linkedin.com/in/kex-websolutions-474418327/'
          >
            <LinkedInIcon />
          </a>
        </div>
      </footer>
    </div>
  )
}

export default Footer
