import React from 'react'
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions'
import SpeedIcon from '@mui/icons-material/Speed'
import PaidIcon from '@mui/icons-material/Paid'
import Katharina from '/Users/xaverebetshuber/Documents/KXE/Website/kxe-web-solutions/src/resources/katharina.webp'
import Elena from '../resources/elena.png'
import Xaver from '../resources/xaver.jpg'
import './style/About.css'
import Forum from '@mui/icons-material/Forum'

const About = () => {
  return (
    <div className='about'>
      <div className='aboutHeading'>
        {' '}
        <h1>Über uns!</h1>
      </div>
      <div className='aboutContent'>
        <div className='aboutTeamDiv'>
          <h2 style={{ marginTop: '2rem' }}>Unser Team</h2>
          <div className='aboutMemberDiv'>
            <div className='teamMember'>
              <img src={Katharina} />
              <h3>Katharina</h3>
              <p>Fotografin</p>
            </div>
            <div className='teamMember'>
              <img src={Elena} />
              <h3>Elena</h3>
              <p>Developerin</p>
            </div>
            <div className='teamMember'>
              <img src={Xaver} />
              <h3>Xaver</h3>
              <p>Developer</p>
            </div>
          </div>
        </div>
        <div className='aboutWerteOuterDiv'>
          <h2>Unsere Unternehmenswerte</h2>
          <div className='aboutWerteDiv'>
            <div className='aboutWert'>
              <div className='aboutWertIcon'>
                <EmojiEmotionsIcon />
              </div>
              <div>
                <h3>Kundenzufriedenheit</h3>
              </div>
            </div>
            <div className='aboutWert'>
              <div className='aboutWertIcon'>
                <SpeedIcon />
              </div>
              <div>
                <h3>Schnelligkeit</h3>
              </div>
            </div>
            <div className='aboutWert'>
              <div className='aboutWertIcon'>
                <PaidIcon />
              </div>
              <div>
                <h3>Leistbarkeit</h3>
              </div>
            </div>
            <div className='aboutWert'>
              <div className='aboutWertIcon'>
                <Forum />
              </div>
              <div>
                <h3>Kommunikation</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
