import React, { useEffect, useState } from 'react'
import { db } from '../firebaseConfig'
import { collection, addDoc } from 'firebase/firestore'
import './style/ContactForm.css'

const ContactForm = ({ selectedPackage }) => {
  // Destructure the selectedPackage prop
  const [submitted, setSubmitted] = useState(false)
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    package: 'basic', // Default package selection
    timeline: '',
    services: {
      extraRevisions: false,
      additionalPages: false,
      customFeatures: false
    }
  })

  // Set the selected package when the prop changes
  useEffect(() => {
    console.log('Selected package:', selectedPackage)
    setFormData(prevData => ({
      ...prevData,
      package: selectedPackage // Update the package in formData based on prop
    }))
  }, [selectedPackage]) // Only run this effect when selectedPackage changes

  const handleChange = e => {
    const { name, value } = e.target
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }))
  }

  const handleCheckboxChange = e => {
    const { name, checked } = e.target
    setFormData(prevData => ({
      ...prevData,
      services: {
        ...prevData.services,
        [name]: checked
      }
    }))
  }

  const handleSubmit = async e => {
    e.preventDefault()

    try {
      await addDoc(collection(db, 'formRequests'), formData)
      setSubmitted(true) // Set to true after submission
      console.log('Form data submitted:', formData)
    } catch (error) {
      console.error('Error adding document:', error)
    }
  }

  return (
    <div className='contactForm'>
      <div className='contactFormHeading'>
        <h1>Kontakt</h1>
      </div>
      {!submitted ? (
        <>
          <form className='contactFormFields' onSubmit={handleSubmit}>
            <div className='contactFormContent'>
              <div className='contactInformationDiv'>
                <label>
                  Name:
                  <input
                    type='text'
                    name='name'
                    value={formData.name}
                    onChange={handleChange}
                    placeholder='Ihr Name'
                    required
                  />
                </label>
                <label>
                  Email:
                  <input
                    type='email'
                    name='email'
                    value={formData.email}
                    onChange={handleChange}
                    placeholder='Ihre Email'
                    required
                  />
                </label>
                <label>
                  Nachricht:
                  <textarea
                    name='message'
                    value={formData.message}
                    onChange={handleChange}
                    placeholder='Ihre Nachricht'
                    required
                  ></textarea>
                </label>
              </div>
              <div className='projectInformationDiv'>
                {/* Package Selection */}
                <label>
                  Paket auswählen:
                  <select
                    name='package'
                    value={formData.package}
                    onChange={handleChange}
                  >
                    <option value='basic'>Basic (299€)</option>
                    <option value='standard'>Standard (599€)</option>
                    <option value='advanced'>Advanced (999€)</option>
                  </select>
                </label>

                {/* Project Timeline */}
                <label>
                  Gewünschte Deadline:
                  <input
                    type='date'
                    name='timeline'
                    value={formData.timeline}
                    onChange={handleChange}
                    placeholder='Projektzeitrahmen'
                  />
                </label>

                {/* Optional Services */}
                <fieldset>
                  <legend>Zusätzliche Services (optional):</legend>
                  <label>
                    <input
                      type='checkbox'
                      name='extraRevisions'
                      checked={formData.services.extraRevisions}
                      onChange={handleCheckboxChange}
                    />
                    Zusätzliche Korrekturrunden
                  </label>
                  <label>
                    <input
                      type='checkbox'
                      name='additionalPages'
                      checked={formData.services.additionalPages}
                      onChange={handleCheckboxChange}
                    />
                    Zusätzliche Seiten
                  </label>
                  <label>
                    <input
                      type='checkbox'
                      name='customFeatures'
                      checked={formData.services.customFeatures}
                      onChange={handleCheckboxChange}
                    />
                    Individuelle Funktionen
                  </label>
                </fieldset>
              </div>
            </div>

            <button type='submit'>Anfrage senden</button>
          </form>
        </>
      ) : (
        <div className='confirmationMessage'>
          <h2>Danke für Ihre Anfrage!</h2>
          <p>Wir werden uns so schnell wie möglich bei Ihnen melden.</p>
        </div>
      )}
    </div>
  )
}

export default ContactForm
