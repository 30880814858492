import React, { useState, useEffect } from 'react'
import MonitorIcon from '@mui/icons-material/Monitor'
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import SettingsIcon from '@mui/icons-material/Settings'
import StarIcon from '@mui/icons-material/Star'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useSwipeable } from 'react-swipeable'
import './style/Offer.css'

const Offer = ({ onPackageSelect }) => {
  const [activeCard, setActiveCard] = useState(0) // State to keep track of the current active card
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1300) // State to detect if it's mobile view
  const [windowWidth, setWindowWidth] = useState(window.innerWidth) // State to track window width

  // Function to update mobile state on window resize
  const handleResize = () => {
    setIsMobile(window.innerWidth < 1300)
    setWindowWidth(window.innerWidth)
  }

  const handleArrowBack = () => {
    if (activeCard === 1) {
      setActiveCard(3)
    } else {
      setActiveCard(activeCard - 1)
    }
  }

  const handleArrowForward = () => {
    if (activeCard === 3) {
      setActiveCard(1)
    } else {
      setActiveCard(activeCard + 1)
    }
  }

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener('resize', handleResize)
    return () => {
      // Cleanup the event listener on component unmount
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (isMobile) {
      setActiveCard(2)
    } else {
      setActiveCard(0)
    }
  }, [isMobile])

  // Swipe Handlers (nur aktiv wenn isMobile true ist)
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handleArrowForward(),
    onSwipedRight: () => handleArrowBack(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true // Option to allow swiping with mouse as well
  })

  const scrollToSection = sectionId => {
    const section = document.getElementById(sectionId)
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const handleAnfrage = angebot => {
    console.log('Anfrage für', angebot)
    if (angebot) {
      scrollToSection('kontakt')
      onPackageSelect(angebot)
    }
  }

  return (
    <div className='offer' {...(isMobile ? swipeHandlers : {})}>
      <div className='offerHeadingDiv'>
        <h1>Unsere Angebote</h1>
        <p>Kostenloses Erstgespräch!</p>
      </div>
      <div className='offerDiv'>
        {windowWidth < 1300 && windowWidth > 550 && (
          <div className='arrowDiv' onClick={() => handleArrowBack()}>
            <ArrowBackIcon style={{ fontSize: '2rem' }} />
          </div>
        )}
        {/* Render Basic Card */}
        {(activeCard === 0 || activeCard === 1) && (
          <div className='offerCard'>
            <div className='offerCardHeader'>
              <MonitorIcon style={{ fontSize: '2rem' }} />
              <h1>Basic</h1>
              <p>Einfacher, professioneller Webauftritt</p>
            </div>
            <div className='offerCardFeatures'>
              <p style={{ border: 'none' }}>One-Page-Webdesign</p>
              <p>Vorlagenbasiertes Design</p>
              <p>Integration von Text und Bildern</p>
              <p>Kontaktformular</p>
              <p>1 Korrekturrunde</p>
            </div>
            <div className='offerCardFooter'>
              <div
                className='offerCardCTAButton'
                onClick={() => handleAnfrage('basic')}
              >
                <p>Jetzt anfragen</p>
              </div>
              <div className='offerCardFooterInnerDiv'>
                <h1>299€</h1>
              </div>
            </div>
          </div>
        )}

        {/* Render Standard Card */}
        {(activeCard === 0 || activeCard === 2) && (
          <div className='offerCard' style={{ backgroundColor: '#333' }}>
            <div
              className='offerCardHeader'
              style={{ backgroundColor: 'white' }}
            >
              <CameraAltIcon style={{ fontSize: '2rem' }} />
              <h1>Standard</h1>
              <p>Stilvolle Website mit professionellen Fotos</p>
            </div>
            <div
              className='offerCardFeatures'
              style={{ color: 'white', position: 'relative' }}
            >
              <p
                style={{
                  position: 'absolute',
                  top: '1px',
                  border: 'none',
                  fontWeight: '200',
                  fontSize: '1rem'
                }}
              >
                <StarIcon style={{ fontSize: '1rem', color: '#c1ff72' }} />
                {'  '}
                Beliebteste Wahl!
              </p>
              <p style={{ border: 'none' }}>One-Page-Webdesign</p>
              <p>Angepasstes Design</p>
              <p> 1-stündiges Fotoshooting</p>
              <p>Kontaktformular</p>
              <p>Sozial Media Integration</p>
              <p>2 Korrekturrunde</p>
            </div>
            <div className='offerCardFooter'>
              <div
                onClick={() => handleAnfrage('standard')}
                className='offerCardCTAButton offerCardCTAButtonSpezial'
              >
                <p>Jetzt anfragen</p>
              </div>
              <div
                className='offerCardFooterInnerDiv'
                style={{ backgroundColor: '#c1ff72', color: '#333' }}
              >
                <h1>599€</h1>
              </div>
            </div>
          </div>
        )}

        {/* Render Advanced Card */}
        {(activeCard === 0 || activeCard === 3) && (
          <div className='offerCard'>
            <div className='offerCardHeader'>
              <SettingsIcon style={{ fontSize: '2rem' }} />
              <h1>Advanced</h1>
              <p>Maßgeschneiderte Websites für Unternehmen</p>
            </div>
            <div className='offerCardFeatures'>
              <p style={{ border: 'none' }}>One-/Multi- Page-Website</p>
              <p>Individuelles Design</p>
              <p>Umfangreiches Fotoshooting</p>
              <p>Erweiterte Inhalte und Layout</p>
              <p>Kontaktformular</p>
              <p>Sozial Media Integration</p>
              <p>3 Korrekturrunden</p>
              <p>SEO-Basics</p>
            </div>
            <div className='offerCardFooter'>
              <div
                className='offerCardCTAButton'
                onClick={() => handleAnfrage('advanced')}
              >
                <p>Jetzt anfragen</p>
              </div>
              <div className='offerCardFooterInnerDiv'>
                <h1>999€</h1>
              </div>
            </div>
          </div>
        )}
        {windowWidth < 1300 && windowWidth > 550 && (
          <div className='arrowDiv' onClick={() => handleArrowForward()}>
            <ArrowForwardIcon style={{ fontSize: '2rem' }} />
          </div>
        )}
      </div>
      {windowWidth < 550 && (
        <div className='arrowOuterDiv'>
          <div className='arrowDiv' onClick={() => handleArrowBack()}>
            <ArrowBackIcon style={{ fontSize: '2rem' }} />
          </div>
          <div className='arrowDiv' onClick={() => handleArrowForward()}>
            <ArrowForwardIcon style={{ fontSize: '2rem' }} />
          </div>
        </div>
      )}
    </div>
  )
}

export default Offer
